import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import * as L from 'leaflet';
import { DivIcon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CircleMarker, MapContainer, Popup, TileLayer, useMap, Marker } from 'react-leaflet';
import { useLocation } from 'react-router-dom';
import { useFilters } from '../contexts/FiltersContext';
import { ClientDetails, MarkerColor } from '../types/interfaces';
import { cleanupDate } from '../utils/cleanupDate';
import EditClientSidebar from './EditClientSidebar';
import RightSidebar from './RightSidebar';

// Naprawiamy problem z ikoną markera
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

interface MapComponentProps {
    klienci: ClientDetails[];
    onMarkerClick: (client: ClientDetails) => void;
    onEditClick: (client: ClientDetails) => void;
}

const MapComponent: React.FC<MapComponentProps> = React.memo(({ klienci, onMarkerClick, onEditClick }) => {
    const map = useMap();
    const [activePopup, setActivePopup] = useState<string | null>(null);
    const { serviceFilters } = useFilters();
    const [filteredKlienci, setFilteredKlienci] = useState(klienci);
    const filterWorkerRef = useRef<Worker | null>(null);
    const location = useLocation();

    useEffect(() => {
        filterWorkerRef.current = new Worker(new URL('../workers/filterWorker.ts', import.meta.url));

        filterWorkerRef.current.onmessage = (e: MessageEvent) => {
            setFilteredKlienci(e.data);
        };

        return () => {
            filterWorkerRef.current?.terminate();
        };
    }, []);

    useEffect(() => {
        if (filterWorkerRef.current) {
            const queryParams = new URLSearchParams(location.search);
            const clientTraits = queryParams.get('client_traits')?.split(',') || [];
            const name = queryParams.get('name') || '';
            const phone = queryParams.get('phone') || '';

            filterWorkerRef.current.postMessage({
                klienci,
                serviceFilters: {
                    overdueOrSoon: clientTraits.includes('overdue_or_soon'),
                    overdue: clientTraits.includes('overdue'),
                    current: clientTraits.includes('current'),
                    resigned: clientTraits.includes('resigned'),
                    not_resigned: clientTraits.includes('not_resigned'),
                    name,
                    phone
                }
            });
        }
    }, [klienci, location.search]);

    const canvasRenderer = useMemo(() => L.canvas({ padding: 0.5 }), []);

    const groupMarkersByLocation = (clients: ClientDetails[]) => {
        const groups: { [key: string]: ClientDetails[] } = {};
        
        clients.forEach(client => {
            const key = `${client.lat},${client.lng}`;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(client);
        });
        
        return Object.values(groups);
    };

    const markers = useMemo(() => {
        const iconColors: Record<MarkerColor, string> = {
            resigned: '#808080',
            red: '#ff4444',
            yellow: '#ffbb33',
            lightyellow: '#fff176',
            green: '#00C851',
            default: '#2196F3',
            purple: '#6200ff'
        };

        const groupedClients = groupMarkersByLocation(filteredKlienci.filter(klient => 
            !!parseFloat(klient.lat) && !!parseFloat(klient.lng)
        ));

        return groupedClients.map((group, index) => {
            const lat = parseFloat(group[0].lat);
            const lng = parseFloat(group[0].lng);
            const popupId = `${lat}-${lng}`;

            if (group.length === 1) {
                return (
                    <CircleMarker
                        key={`single-${popupId}-${index}`}
                        center={[lat, lng]}
                        radius={8}
                        pathOptions={{
                            renderer: canvasRenderer,
                            color: iconColors[group[0].color || 'purple'],
                            fillColor: iconColors[group[0].color || 'purple'],
                            fillOpacity: 0.7,
                            weight: 2
                        }}
                        eventHandlers={{
                            click: (e: any) => {
                                e.originalEvent.stopPropagation();
                                setActivePopup(popupId);
                            }
                        }}
                    >
                        <Popup>
                            <Box sx={{ maxHeight: '600px', overflowY: 'auto' }}>
                                {group.map((client, clientIndex) => (
                                    <Box key={client.id} sx={{ 
                                        p: 1, 
                                        mb: clientIndex < group.length - 1 ? 2 : 0,
                                        borderBottom: clientIndex < group.length - 1 ? '1px solid #eee' : 'none'
                                    }}>
                                        <Typography variant="h6">{client.name}</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Adres:</strong> {client.address}
                                        </Typography>
                                        {(client.phone1 || client.phone2) && (
                                            <Typography variant="body2" gutterBottom>
                                                <strong>Telefon:</strong> {[client.phone1, client.phone2].filter(Boolean).join(', ')}
                                            </Typography>
                                        )}
                                        {client.power && (
                                            <Typography variant="body2" gutterBottom>
                                                <strong>Moc:</strong> {client.power}
                                            </Typography>
                                        )}
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Typ:</strong> {client.serviceType || 'Brak'}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Status:</strong> {client.resigned ? 'Rezygnacja' : 'Aktywny'}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Instalator:</strong> {client.installer || 'Brak'}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Data instalacji:</strong> {client.installationDate ? cleanupDate(client.installationDate) : 'Brak'}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Ostatni zaplanowany przegląd:</strong> {client.lastScheduledServiceDate ? cleanupDate(client.lastScheduledServiceDate) : 'Brak'}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Ostatni wykonany przegląd:</strong> {client.lastServiceDate ? cleanupDate(client.lastServiceDate) : 'Brak'}
                                        </Typography>
                                        <Box sx={{ mt: 1 }}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onMarkerClick(client);
                                                }}
                                            >
                                                Szczegóły
                                            </Button>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Popup>
                    </CircleMarker>
                );
            }

            const total = group.length;
            let currentAngle = 0;
            const radius = 12;
            const paths = group.map((client, i) => {
                const percentage = 1 / total;
                const startAngle = currentAngle;
                const endAngle = currentAngle + (percentage * 2 * Math.PI);
                currentAngle = endAngle;

                const startX = radius * Math.cos(startAngle);
                const startY = radius * Math.sin(startAngle);
                const endX = radius * Math.cos(endAngle);
                const endY = radius * Math.sin(endAngle);

                const largeArcFlag = percentage > 0.5 ? 1 : 0;

                const pathData = [
                    `M 0 0`,
                    `L ${startX} ${startY}`,
                    `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`,
                    'Z'
                ].join(' ');

                return `<path d="${pathData}" fill="${iconColors[client.color || 'purple']}" stroke="white" stroke-width="1"/>`;
            });

            const svgContent = `
                <svg width="24" height="24" viewBox="-13 -13 26 26">
                    ${paths.join('\n')}
                </svg>
            `;

            const icon = new DivIcon({
                html: svgContent,
                className: '',
                iconSize: [24, 24]
            });

            return (
                <Marker
                    key={`group-${popupId}-${index}`}
                    position={[lat, lng]}
                    icon={icon}
                    eventHandlers={{
                        click: (e: any) => {
                            e.originalEvent.stopPropagation();
                            setActivePopup(popupId);
                        }
                    }}
                >
                    <Popup>
                        <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {group.map((client, clientIndex) => (
                                <Box key={client.id} sx={{ 
                                    p: 1, 
                                    mb: clientIndex < group.length - 1 ? 2 : 0,
                                    borderBottom: clientIndex < group.length - 1 ? '1px solid #eee' : 'none'
                                }}>
                                    <Typography variant="h6">{client.name}</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>Adres:</strong> {client.address}
                                    </Typography>
                                    {(client.phone1 || client.phone2) && (
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Telefon:</strong> {[client.phone1, client.phone2].filter(Boolean).join(', ')}
                                        </Typography>
                                    )}
                                    {client.power && (
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Moc:</strong> {client.power}
                                        </Typography>
                                    )}
                                    <Typography variant="body2" gutterBottom>
                                        <strong>Typ:</strong> {client.serviceType || 'Brak'}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>Status:</strong> {client.resigned ? 'Rezygnacja' : 'Aktywny'}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>Instalator:</strong> {client.installer || 'Brak'}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>Data instalacji:</strong> {client.installationDate ? cleanupDate(client.installationDate) : 'Brak'}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>Ostatni zaplanowany przegląd:</strong> {client.lastScheduledServiceDate ? cleanupDate(client.lastScheduledServiceDate) : 'Brak'}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>Ostatni wykonany przegląd:</strong> {client.lastServiceDate ? cleanupDate(client.lastServiceDate) : 'Brak'}
                                    </Typography>
                                    <Box sx={{ mt: 1 }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onMarkerClick(client);
                                            }}
                                        >
                                            Szczegóły
                                        </Button>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Popup>
                </Marker>
            );
        });
    }, [filteredKlienci, canvasRenderer, onMarkerClick]);

    return <>{markers}</>;
}, (prevProps, nextProps) => {
    return prevProps.klienci === nextProps.klienci;
});

const ListaKlientow: React.FC = React.memo(() => {
    const [loading, setLoading] = useState(true);
    const [klienci, setKlienci] = useState<ClientDetails[]>([]);
    const [filteredKlienci, setFilteredKlienci] = useState<ClientDetails[]>([]);
    const filterWorkerRef = useRef<Worker | null>(null);
    const location = useLocation();
    const [selectedClient, setSelectedClient] = useState<ClientDetails | null>(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    // Inicjalizacja workera tylko raz
    useEffect(() => {
        filterWorkerRef.current = new Worker(new URL('../workers/filterWorker.ts', import.meta.url));

        return () => {
            filterWorkerRef.current?.terminate();
        };
    }, []);

    // Zdefiniowanie fetchClients jako useCallback
    const fetchClients = useCallback(async () => {
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch('/api/clients', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            const clientsData = Array.isArray(data) ? data : Object.values(data);
            setKlienci(clientsData);
            setFilteredKlienci(clientsData);
            setLoading(false);
        } catch (error) {
            console.error('Błąd podczas pobierania klientów:', error);
            setKlienci([]);
            setFilteredKlienci([]);
            setLoading(false);
        }
    }, [getAccessTokenSilently]);

    // Pobieranie klientów przy montowaniu komponentu
    useEffect(() => {
        let isMounted = true;

        const loadClients = async () => {
            if (isMounted) {
                await fetchClients();
            }
        };

        loadClients();
        return () => {
            isMounted = false;
        };
    }, [fetchClients]);

    const handleEditClick = useCallback(() => {
        console.log('handleEditClick wywołane');
        setIsEditMode(true);
    }, []);

    const handleClientClick = useCallback((client: ClientDetails) => {
        console.log('handleClientClick wywołane dla:', client.id);
        setSelectedClient(client);
        setSidebarOpen(true);
        setIsEditMode(false);
    }, []);

    // Komponent mapy wydzielony jako osobny komponent z memo
    const MapWithMarkers = useMemo(() => (
        <MapContainer
            center={[52.069167, 19.480556]}
            zoom={6}
            style={{ height: '100%', width: '100%' }}
            preferCanvas={true}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapComponent
                klienci={filteredKlienci}
                onMarkerClick={handleClientClick}
                onEditClick={handleClientClick}
            />
        </MapContainer>
    ), [filteredKlienci, handleClientClick]);

    const renderSidebars = useMemo(() => (
        <>
            {!isEditMode && selectedClient && (
                <RightSidebar
                    open={sidebarOpen}
                    onClose={() => {
                        setSidebarOpen(false);
                        setSelectedClient(null);
                        setIsEditMode(false);
                    }}
                    clientDetails={selectedClient}
                    isEditMode={isEditMode}
                    onEditClick={handleEditClick}
                />
            )}
            {isEditMode && selectedClient && (
                <EditClientSidebar
                    open={sidebarOpen}
                    onClose={() => {
                        setSidebarOpen(false);
                        setSelectedClient(null);
                        setIsEditMode(false);
                    }}
                    clientDetails={selectedClient}
                    onUpdate={async () => {
                        await fetchClients();
                        setIsEditMode(false);
                        setSidebarOpen(false);
                        setSelectedClient(null);
                    }}
                />
            )}
        </>
    ), [isEditMode, selectedClient, sidebarOpen, handleEditClick, fetchClients]);

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Box sx={{ flexGrow: 1, height: '100%' }}>
                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    MapWithMarkers
                )}
            </Box>
            {renderSidebars}
        </Box>
    );
}, (prevProps, nextProps) => true); // Zawsze zwracaj true, bo komponent nie ma propsów

export default ListaKlientow;
