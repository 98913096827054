import React, { useState, useEffect } from 'react';
import { 
  Drawer, 
  Typography, 
  Box, 
  TextField, 
  Button, 
  Grid, 
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { ClientDetails } from '../types/interfaces';

interface EditClientSidebarProps {
  open: boolean;
  onClose: () => void;
  clientDetails: ClientDetails | null;
  onUpdate: () => Promise<void>;
}

const EditClientSidebar: React.FC<EditClientSidebarProps> = ({
  open,
  onClose,
  clientDetails,
  onUpdate
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [editedClient, setEditedClient] = useState<Partial<ClientDetails>>({});
  const [error, setError] = useState<string | null>(null);

  const fetchCoordinates = async (address: string) => {
    const apiKey = "54cb09748c35400f81ae85366259c912";
    const encodedAddress = encodeURIComponent(address);
    const url = `https://api.geoapify.com/v1/geocode/search?text=${encodedAddress}&format=json&apiKey=${apiKey}`;

    const response = await fetch(url);
    const data = await response.json();
    
    if (data.results && data.results.length > 0) {
      const { lat, lon } = data.results[0];
      return { lat, lng: lon };
    }
    throw new Error('Nie udało się pobrać współrzędnych');
  };

  useEffect(() => {
    if (clientDetails) {
      setEditedClient({
        ...clientDetails,
        installationDate: clientDetails.installationDate?.split('T')[0] || '',
        agreementSigningDate: clientDetails.agreementSigningDate?.split('T')[0] || '',
        agreementIntroductionDate: clientDetails.agreementIntroductionDate?.split('T')[0] || ''
      });
    }
  }, [clientDetails]);

  const handleChange = (field: string, value: any) => {
    setEditedClient(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editedClient) return;

    try {
      const token = await getAccessTokenSilently();
      const coordinates = await fetchCoordinates(editedClient.address || '');
      
      const dataToSubmit = {
        name: editedClient.name,
        address: editedClient.address,
        phone1: editedClient.phone1 || '',
        phone2: editedClient.phone2 || '',
        power: editedClient.power,
        installation_date: editedClient.installationDate,
        agreement_signing_date: editedClient.agreementSigningDate,
        agreement_introduction_date: editedClient.agreementIntroductionDate,
        installer: editedClient.installer,
        lat: coordinates.lat,
        lng: coordinates.lng,
        service_type: editedClient.serviceType || '',
        resigned: editedClient.resigned || false
      };

      const response = await fetch(`/api/clients/${editedClient.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSubmit)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Błąd podczas aktualizacji klienta');
      }

      await onUpdate();
      onClose();
    } catch (error) {
      console.error('Błąd:', error);
      setError(error instanceof Error ? error.message : 'Wystąpił błąd');
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 400 } }}
    >
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Edycja klienta
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Imię i nazwisko"
                value={editedClient.name || ''}
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Adres"
                value={editedClient.address || ''}
                onChange={(e) => handleChange('address', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Telefon 1"
                value={editedClient.phone1 || ''}
                onChange={(e) => handleChange('phone1', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Telefon 2"
                value={editedClient.phone2 || ''}
                onChange={(e) => handleChange('phone2', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Moc"
                value={editedClient.power || ''}
                onChange={(e) => handleChange('power', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="Data instalacji"
                value={editedClient.installationDate || ''}
                onChange={(e) => handleChange('installationDate', e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="Data umowy"
                value={editedClient.agreementSigningDate || ''}
                onChange={(e) => handleChange('agreementSigningDate', e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Osoba odpowiedzialna"
                value={editedClient.installer || ''}
                onChange={(e) => handleChange('installer', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Typ instalacji"
                value={editedClient.serviceType || ''}
                onChange={(e) => handleChange('serviceType', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedClient.resigned || false}
                    onChange={(e) => handleChange('resigned', e.target.checked)}
                  />
                }
                label="Rezygnacja"
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
              <Button onClick={onClose} variant="outlined">
                Anuluj
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Zapisz zmiany
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Drawer>
  );
};

export default EditClientSidebar;
