import React, { createContext, useContext, useState } from 'react';

export type Filters = {
  name?: string;
  phone?: string;
  resigned: 'resigned' | 'not_resigned' | 'neutral';
  service_time: 'neutral' | 'service_soon' | 'overdue' | 'overdue_or_soon';
  installationDateFrom?: string;
  installationDateTo?: string;
  lastServiceDateFrom?: string;
  lastServiceDateTo?: string;
}

interface FiltersContextType {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  serviceFilters: {
    overdueOrSoon: boolean;
    overdue: boolean;
    current: boolean;
  };
  setServiceFilters: React.Dispatch<React.SetStateAction<{
    overdueOrSoon: boolean;
    overdue: boolean;
    current: boolean;
  }>>;
}

const FiltersContext = createContext<FiltersContextType>({
  filters: {
    resigned: 'neutral',
    service_time: 'neutral'
  },
  setFilters: () => {},
  serviceFilters: {
    overdueOrSoon: false,
    overdue: false,
    current: false
  },
  setServiceFilters: () => {}
});

export const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<Filters>({
    resigned: 'neutral',
    service_time: 'neutral'
  });

  const [serviceFilters, setServiceFilters] = useState({
    overdueOrSoon: false,
    overdue: false,
    current: false
  });

  return (
    <FiltersContext.Provider value={{ filters, setFilters, serviceFilters, setServiceFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }
  return context;
};
