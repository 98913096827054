import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {Box, CircularProgress, CssBaseline} from '@mui/material';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import theme from './theme';
import Dashboard from './components/Dashboard';
import NowyFormularz from './components/NowyFormularz';
import ListaKlientow from './components/ListaKlientow';
import Sidebar from './components/Sidebar';
import LoginButton from './components/LoginButton';
import {useAuth0} from '@auth0/auth0-react';
import {FiltersProvider} from './contexts/FiltersContext';
import TabelaKlientow from './components/TabelaKlientow';

const MapRoute = React.memo(() => {
    const {isAuthenticated} = useAuth0();
    return isAuthenticated ? <ListaKlientow/> : <Navigate to="/"/>;
});

const App: React.FC = () => {
    const {isLoading, isAuthenticated} = useAuth0();

    if (isLoading) {
        return <Box
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}><CircularProgress/></Box>;
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <FiltersProvider>
                <Router>
                    <Box sx={{
                        display: 'flex',
                        minHeight: '100vh',
                        backgroundColor: '#1e1e1e',
                        width: '100%'
                    }}>
                        {isAuthenticated && <Sidebar/>}
                        <Box component="main" sx={{
                            flexGrow: 1,
                            marginLeft: isAuthenticated ? '10px' : 0,
                            width: isAuthenticated ? '90%' : '100%',
                            minHeight: '100vh',
                            backgroundColor: '#1e1e1e',
                            padding: 0
                        }}>
                            <Routes>
                                <Route path="/"
                                       element={isAuthenticated ? <Navigate to="/dashboard"/> : <LoginButton/>}/>
                                <Route path="/dashboard" element={isAuthenticated ? <Dashboard/> : <Navigate to="/"/>}/>
                                <Route path="/mapa" element={<MapRoute/>}/>
                                <Route path="/nowy" element={isAuthenticated ? <NowyFormularz/> : <Navigate to="/"/>}/>
                                <Route path="/tabela"
                                       element={isAuthenticated ? <TabelaKlientow/> : <Navigate to="/"/>}/>
                            </Routes>
                        </Box>
                    </Box>
                </Router>
            </FiltersProvider>
        </ThemeProvider>
    );
};

export default App;
