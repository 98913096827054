import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    Drawer,
    FormControl,
    FormControlLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import {useAuth0} from '@auth0/auth0-react';
import {Filters, useFilters} from '../contexts/FiltersContext';
import {
    Dashboard as DashboardIcon,
    Map as MapIcon,
    People as PeopleIcon,
    PersonAdd as PersonAddIcon,
    TableChart as TableChartIcon,
} from '@mui/icons-material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import useDebounce from '../hooks/useDebounce';

// Najpierw zdefiniujmy typ dla filtrów serwisowych
type ServiceFilterType = 'overdueOrSoon' | 'overdue' | 'current';

const Sidebar: React.FC = () => {
    const {user, logout} = useAuth0();
    const [openKlienci, setOpenKlienci] = useState(false);
    const location = useLocation();
    const {filters, setFilters, serviceFilters, setServiceFilters} = useFilters();
    const navigate = useNavigate();

    const [localName, setLocalName] = useState('');
    const [localPhone, setLocalPhone] = useState('');
    const [statusFilters, setStatusFilters] = useState({
        notResigned: true,
        resigned: false
    });

    const debouncedName = useDebounce(localName, 500);
    const debouncedPhone = useDebounce(localPhone, 500);

    // Inicjalizacja filtrów przy pierwszym renderowaniu i zmianie URL
    useEffect(() => {
        const params = new URLSearchParams(location.search);

        // Ustawienie filtra nazwy
        const name = params.get('name');
        if (name) {
            setLocalName(decodeURIComponent(name));
        }

        // Ustawienie filtra telefonu
        const phone = params.get('phone');
        if (phone) {
            setLocalPhone(decodeURIComponent(phone));
        }

        // Ustawienie filtrów na podstawie client_traits
        const traits = params.get('client_traits')?.split(',') || [];

        setStatusFilters({
            notResigned: traits.includes('not_resigned'),
            resigned: traits.includes('resigned')
        });

        setServiceFilters({
            overdueOrSoon: traits.includes('overdue_or_soon'),
            overdue: traits.includes('overdue'),
            current: traits.includes('current'),
        });
    }, [location.search]); // Reaguj na zmiany w URL

    useEffect(() => {
        if (debouncedName !== undefined) {
            handleFilterChange('name', debouncedName);
        }
    }, [debouncedName]);

    useEffect(() => {
        if (debouncedPhone !== undefined) {
            handleFilterChange('phone', debouncedPhone);
        }
    }, [debouncedPhone]);

    const handleKlienciClick = () => {
        setOpenKlienci(!openKlienci);
    };

    const showFilters = location.pathname === '/mapa';
    const queryParams = new URLSearchParams(location.search);
    const clientTraits = queryParams.get('client_traits')?.split(',') || [];

    const getMapUrl = () => {
        return `/mapa?client_traits=not_resigned,overdue_or_soon`;
    };

    const handleFilterChange = (filterName: keyof Filters, value: string) => {
        // Aktualizuj stan lokalny i kontekst
        const newFilters = {...filters, [filterName]: value};
        setFilters(newFilters);
        
        // Aktualizuj parametry URL
        const searchParams = new URLSearchParams(location.search);
        
        if (filterName === 'name' || filterName === 'phone') {
            if (value) {
                searchParams.set(filterName, value);
                // Aktualizuj serviceFilters dla workera
                setServiceFilters(prev => ({
                    ...prev,
                    [filterName]: value
                }));
            } else {
                searchParams.delete(filterName);
                // Usuń filtr z serviceFilters
                setServiceFilters(prev => ({
                    ...prev,
                    [filterName]: undefined
                }));
            }
        }
        
        // Zachowaj pozostałe parametry URL
        const clientTraits = searchParams.get('client_traits')?.split(',') || [];
        if (clientTraits.length > 0) {
            searchParams.set('client_traits', clientTraits.join(','));
        }
        
        navigate({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalName(e.target.value);
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalPhone(e.target.value);
    };

    const handleStatusFilterChange = (checked: boolean, filterType: 'notResigned' | 'resigned') => {
        const newStatusFilters = {
            ...statusFilters,
            [filterType]: checked
        };
        setStatusFilters(newStatusFilters);

        const searchParams = new URLSearchParams(location.search);

        // Tworzymy nowy zestaw unikalnych traits
        const uniqueTraits = new Set<string>();

        // Dodajemy filtry statusu
        if (newStatusFilters.notResigned) uniqueTraits.add('not_resigned');
        if (newStatusFilters.resigned) uniqueTraits.add('resigned');

        // Dodajemy aktywne filtry serwisowe
        if (serviceFilters.overdueOrSoon) uniqueTraits.add('overdue_or_soon');
        if (serviceFilters.overdue) uniqueTraits.add('overdue');
        if (serviceFilters.current) uniqueTraits.add('current');

        // Konwertujemy Set na tablicę i ustawiamy w URL
        const newTraits = Array.from(uniqueTraits);
        searchParams.set('client_traits', newTraits.join(','));

        navigate({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    };

    const handleServiceFilterChange = (checked: boolean, filterType: ServiceFilterType) => {
        const newServiceFilters = {
            ...serviceFilters,
            [filterType]: checked
        };
        setServiceFilters(newServiceFilters);

        // Nie wysyłamy nowego requestu, tylko aktualizujemy URL
        const searchParams = new URLSearchParams(location.search);
        const uniqueTraits = new Set<string>();

        // Zachowujemy filtry statusu
        if (statusFilters.notResigned) uniqueTraits.add('not_resigned');
        if (statusFilters.resigned) uniqueTraits.add('resigned');

        // Dodajemy aktywne filtry serwisowe
        if (newServiceFilters.overdueOrSoon) uniqueTraits.add('overdue_or_soon');
        if (newServiceFilters.overdue) uniqueTraits.add('overdue');
        if (newServiceFilters.current) uniqueTraits.add('current');

        // Aktualizujemy URL bez wysyłania nowego żądania
        const newTraits = Array.from(uniqueTraits);
        searchParams.set('client_traits', newTraits.join(','));

        navigate({
            pathname: location.pathname,
            search: searchParams.toString()
        }, {replace: true}); // dodajemy replace: true aby nie tworzyć nowej historii
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: 280,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 280,
                    boxSizing: 'border-box',
                    backgroundColor: '#333',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    overflowY: 'auto',
                },
            }}
        >
            <Box sx={{p: 2}}>
                <Typography variant="h6" sx={{color: '#4fc3f7'}}>PROMAG</Typography>
            </Box>

            <List>
                <ListItem button component={Link} to="/dashboard">
                    <ListItemIcon>
                        <DashboardIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Dashboard"/>
                </ListItem>

                <ListItem button onClick={handleKlienciClick}>
                    <ListItemIcon>
                        <PeopleIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Klienci"/>
                    {openKlienci ? <ExpandLess sx={{color: 'white'}}/> : <ExpandMore sx={{color: 'white'}}/>}
                </ListItem>

                <Collapse in={openKlienci} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button component={Link} to="/tabela" sx={{pl: 4}}>
                            <ListItemIcon>
                                <TableChartIcon sx={{color: 'white'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Lista"/>
                        </ListItem>

                        <ListItem button component={Link} to={getMapUrl()} sx={{pl: 4}}>
                            <ListItemIcon>
                                <MapIcon sx={{color: 'white'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Mapa"/>
                        </ListItem>

                        <ListItem button component={Link} to="/nowy" sx={{pl: 4}}>
                            <ListItemIcon>
                                <PersonAddIcon sx={{color: 'white'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Dodaj nowego"/>
                        </ListItem>
                    </List>
                </Collapse>
            </List>

            {showFilters && (
                <Box sx={{p: 2}}>
                    <Typography variant="h6" sx={{color: '#4fc3f7', mb: 2}}>
                        Filtry
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <TextField
                                fullWidth
                                label="Szukaj po nazwie"
                                value={localName}
                                onChange={handleNameChange}
                                sx={{mb: 2}}
                            />
                        </ListItem>
                        <ListItem disablePadding>
                            <TextField
                                fullWidth
                                label="Szukaj po telefonie"
                                value={localPhone}
                                onChange={handlePhoneChange}
                                sx={{mb: 2}}
                            />
                        </ListItem>
                        <ListItem disablePadding sx={{mb: 2}}>
                            <FormControl component="fieldset">
                                <Typography variant="subtitle2" sx={{color: '#fff', mb: 1}}>
                                    Status klienta
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={statusFilters.notResigned}
                                                onChange={(e) => handleStatusFilterChange(e.target.checked, 'notResigned')}
                                                sx={{color: '#fff'}}
                                            />
                                        }
                                        label="Nie zrezygnowali"
                                        sx={{color: '#fff'}}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={statusFilters.resigned}
                                                onChange={(e) => handleStatusFilterChange(e.target.checked, 'resigned')}
                                                sx={{color: '#fff'}}
                                            />
                                        }
                                        label="Zrezygnowali"
                                        sx={{color: '#fff'}}
                                    />
                                </Box>
                            </FormControl>
                        </ListItem>
                        <ListItem disablePadding sx={{mb: 2}}>
                            <FormControl component="fieldset">
                                <Typography variant="subtitle2" sx={{color: '#fff', mb: 1}}>
                                    Przegląd
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={serviceFilters.current}
                                                onChange={(e) => handleServiceFilterChange(e.target.checked, 'current')}
                                                sx={{color: '#fff'}}
                                            />
                                        }
                                        label="Aktualne"
                                        sx={{color: '#fff'}}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={serviceFilters.overdueOrSoon}
                                                onChange={(e) => handleServiceFilterChange(e.target.checked, 'overdueOrSoon')}
                                                sx={{color: '#fff'}}
                                            />
                                        }
                                        label="Do umówienia"
                                        sx={{color: '#fff'}}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={serviceFilters.overdue}
                                                onChange={(e) => handleServiceFilterChange(e.target.checked, 'overdue')}
                                                sx={{color: '#fff'}}
                                            />
                                        }
                                        label="Po terminie"
                                        sx={{color: '#fff'}}
                                    />
                                </Box>
                            </FormControl>
                        </ListItem>
                    </List>
                </Box>
            )}

            <Box sx={{mt: 'auto', p: 2}}>
                {user && (
                    <Typography variant="body2" sx={{mb: 2, color: '#999'}}>
                        Zalogowany jako: {user.email}
                    </Typography>
                )}
                <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    startIcon={<LogoutIcon/>}
                    onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}
                >
                    Wyloguj
                </Button>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
