import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const StatCard = ({ Icon, title, value, unit }: { Icon: React.ElementType, title: string, value: string, unit?: string }) => (
  <Paper
    elevation={3}
    sx={{
      p: 3,
      bgcolor: '#2C2C2C',
      transition: 'all 0.3s',
      '&:hover': {
        bgcolor: '#3C3C3C',
        transform: 'translateY(-5px)',
      }
    }}
  >
    <Box display="flex" alignItems="center" mb={2}>
      <Icon sx={{ fontSize: 40, mr: 2, color: '#64B5F6' }} />
      <Typography variant="h6" color="text.secondary">
        {title}
      </Typography>
    </Box>
    <Typography variant="h4" component="div" fontWeight="bold">
      {value}
      {unit && <Typography component="span" variant="h5" ml={1}>{unit}</Typography>}
    </Typography>
  </Paper>
);

const Dashboard = () => {
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
        Dashboard
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            Icon={PeopleIcon}
            title="Klienci"
            value="120"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            Icon={AttachMoneyIcon}
            title="Przychody"
            value="15,000"
            unit="zł"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            Icon={TrendingUpIcon}
            title="Wzrost"
            value="25"
            unit="%"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
