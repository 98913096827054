import React, {useEffect, useState} from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
  Typography,
  Divider
} from '@mui/material';
import {useAuth0} from '@auth0/auth0-react';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {ClientDetails, ServiceDetails} from '../types/interfaces';
import EditClientSidebar from './EditClientSidebar';
import { cleanupDate } from '../utils/cleanupDate';

interface RightSidebarProps {
  open: boolean;
  onClose: () => void;
  clientDetails: ClientDetails;
  isEditMode: boolean;
  onEditClick: () => void;
}

const RightSidebar: React.FC<RightSidebarProps> = ({ open, onClose, clientDetails, isEditMode, onEditClick }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [serviceDetails, setServiceDetails] = useState<ServiceDetails[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedService, setEditedService] = useState<ServiceDetails | null>(null);
  const [expandedServiceId, setExpandedServiceId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadClientWithServices = async () => {
      if (open && clientDetails?.id) {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch(`/api/clients/${clientDetails.id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            throw new Error('Nie udało się pobrać danych klienta');
          }

          const data = await response.json();
          setServiceDetails(data.services || []);
        } catch (error) {
          setError('Błąd podczas pobierania danych serwisowych');
          setServiceDetails([]);
        } finally {
          setLoading(false);
        }
      }
    };

    loadClientWithServices();
  }, [open, clientDetails?.id, getAccessTokenSilently]);

  const fetchServiceDetails = async (clientId: number) => {
    console.log('fetchServiceDetails ', clientId);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`/api/services/${clientId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Nie udało się pobrać danych serwisowych');
      }

      const data = await response.json();
      console.log(data);
      setServiceDetails(clientDetails?.services || []);
    } catch (error) {
      setError('Błąd podczas pobierania danych serwisowych');
      setServiceDetails([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchClientDetails = async (clientId: number) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`/api/clients/${clientId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      setError('Błąd podczas pobierania danych klienta');
    }
    return null;
  };

  const handleCreateService = async () => {
    if (!editedService || !clientDetails?.id) return;

    try {
      const token = await getAccessTokenSilently();
      
      const dataToSend = {
        client_id: clientDetails.id.toString(),
        scheduled_service_date: editedService.scheduledServiceDate ? new Date(editedService.scheduledServiceDate).toISOString() : null,
        scheduled_anode_date: editedService.scheduledAnodeDate ? new Date(editedService.scheduledAnodeDate).toISOString() : null,
        scheduled_glycol_date: editedService.scheduledGlycolDate ? new Date(editedService.scheduledGlycolDate).toISOString() : null,
        scheduled_amount: parseFloat(editedService.scheduledAmount) || 0,
        scheduled_scope: editedService.scheduledScope || '',
        scheduled_description: editedService.scheduledDescription || '',
        scheduled_by: editedService.scheduledBy || '',
        service_date: editedService.serviceDate ? new Date(editedService.serviceDate).toISOString() : null,
        anode_date: editedService.anodeDate ? new Date(editedService.anodeDate).toISOString() : null,
        glycol_date: editedService.glycolDate ? new Date(editedService.glycolDate).toISOString() : null,
        service_amount: parseFloat(editedService.serviceAmount) || 0,
        service_scope: editedService.serviceScope || '',
        service_description: editedService.serviceDescription || '',
        service_person: editedService.servicePerson || '',
        service_done: editedService.serviceDone || 0
      };

      const response = await fetch('/api/services', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Nie udało się utworzyć przeglądu');
      }

      const updatedData = await fetchClientDetails(clientDetails.id);
      if (updatedData) {
        setServiceDetails(updatedData.services || []);
      }
      
      setIsEditing(false);
      setEditedService(null);
    } catch (error) {
      console.error('Błąd podczas tworzenia przeglądu:', error);
    }
  };

  const handleUpdateService = async () => {
    console.log('handleUpdateService');
    if (!editedService?.id || !clientDetails?.id) return;
    
    try {
      const token = await getAccessTokenSilently();
      
      const dataToSend = {
        client_id: clientDetails.id.toString(),
        scheduled_service_date: editedService.scheduledServiceDate ? new Date(editedService.scheduledServiceDate).toISOString() : null,
        scheduled_anode_date: editedService.scheduledAnodeDate ? new Date(editedService.scheduledAnodeDate).toISOString() : null,
        scheduled_glycol_date: editedService.scheduledGlycolDate ? new Date(editedService.scheduledGlycolDate).toISOString() : null,
        scheduled_amount: parseFloat(editedService.scheduledAmount) || 0,
        scheduled_scope: editedService.scheduledScope || '',
        scheduled_description: editedService.scheduledDescription || '',
        scheduled_by: editedService.scheduledBy || '',
        service_date: editedService.serviceDate ? new Date(editedService.serviceDate).toISOString() : null,
        anode_date: editedService.anodeDate ? new Date(editedService.anodeDate).toISOString() : null,
        glycol_date: editedService.glycolDate ? new Date(editedService.glycolDate).toISOString() : null,
        service_amount: parseFloat(editedService.serviceAmount) || 0,
        service_scope: editedService.serviceScope || '',
        service_description: editedService.serviceDescription || '',
        service_person: editedService.servicePerson || '',
        service_done: editedService.serviceDone || 0
      };

      console.log('Dane wysyłane do API:', dataToSend);

      const response = await fetch(`/api/services/${editedService.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Nie udało się zaktualizować przeglądu');
      }

      const updatedData = await fetchClientDetails(clientDetails.id);
      if (updatedData) {
        setServiceDetails(updatedData.services || []);
      }
      
      setIsEditing(false);
      setEditedService(null);
    } catch (error) {
      console.error('Błąd podczas aktualizacji przeglądu:', error);
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    setEditedService(prev => {
      if (!prev) {
        return {
          id: 0,
          client_id: clientDetails?.id || 0,
          serviceDate: null,
          anodeDate: null,
          glycolDate: null,
          servicePerson: '',
          serviceAmount: '0',
          serviceScope: '',
          serviceDescription: '',
          scheduledServiceDate: null,
          scheduledAnodeDate: null,
          scheduledGlycolDate: null,
          scheduledBy: '',
          scheduledAmount: '0',
          scheduledScope: '',
          scheduledDescription: '',
          executionAnodeDate: null,
          executionGlycolDate: null,
          executionPerson: '',
          executionAmount: '',
          executionScope: '',
          executionDescription: '',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          serviceDone: 0
        };
      }
      return {
        ...prev,
        [field]: value
      };
    });
  };

  const renderServiceForm = () => {
    console.log('renderServiceForm');
    console.log(editedService);
    
    return (
      <form onSubmit={(e) => {
        e.preventDefault();
        if (editedService?.id) {
          handleUpdateService();
        } else {
          handleCreateService();
        }
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Planowany serwis</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Data umówionego przeglądu"
              type="date"
              value={cleanupDate(editedService?.scheduledServiceDate)}
              onChange={(e) => handleFieldChange('scheduledServiceDate', e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Data umówionej wymiany anody"
              type="date"
              value={cleanupDate(editedService?.scheduledAnodeDate)}
              onChange={(e) => handleFieldChange('scheduledAnodeDate', e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Data umówionej wymiany glikolu"
              type="date"
              value={cleanupDate(editedService?.scheduledGlycolDate)}
              onChange={(e) => handleFieldChange('scheduledGlycolDate', e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Osoba która umówiła"
              value={editedService?.scheduledBy || ''}
              onChange={(e) => handleFieldChange('scheduledBy', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Kwota"
              type="text"
              value={editedService?.scheduledAmount || ''}
              onChange={(e) => handleFieldChange('scheduledAmount', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Zakres"
              value={editedService?.scheduledScope || ''}
              onChange={(e) => handleFieldChange('scheduledScope', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Opis"
              multiline
              rows={2}
              value={editedService?.scheduledDescription || ''}
              onChange={(e) => handleFieldChange('scheduledDescription', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Wykonany serwis</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Data wykonania przeglądu"
              type="date"
              value={cleanupDate(editedService?.serviceDate)}
              onChange={(e) => handleFieldChange('serviceDate', e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Data wykonania wymiany anody"
              type="date"
              value={cleanupDate(editedService?.anodeDate)}
              onChange={(e) => handleFieldChange('anodeDate', e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                fullWidth
                label="Data wykonania wymiany glikolu"
                type="date"
                value={cleanupDate(editedService?.glycolDate)}
                onChange={(e) => handleFieldChange('glycolDate', e.target.value)}
                InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Osoba wykonująca serwis"
              value={editedService?.servicePerson || ''}
              onChange={(e) => handleFieldChange('servicePerson', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Kwota serwis"
              type="text"
              value={editedService?.serviceAmount || ''}
              onChange={(e) => handleFieldChange('serviceAmount', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Zakres serwis"
              value={editedService?.serviceScope || ''}
              onChange={(e) => handleFieldChange('serviceScope', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Opis"
              multiline
              rows={2}
              value={editedService?.serviceDescription || ''}
              onChange={(e) => handleFieldChange('serviceDescription', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(editedService?.serviceDone)}
                  onChange={(e) => handleFieldChange('serviceDone', e.target.checked ? 1 : 0)}
                />
              }
              label="Serwis wykonany"
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
            <Button onClick={() => {
              setIsEditing(false);
              setEditedService(null);
            }} variant="outlined">
              Anuluj
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {editedService?.id ? 'Zapisz zmiany' : 'Dodaj przegląd'}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };

  const handleServiceEdit = (service: ServiceDetails) => {
    console.log('Oryginalne dane serwisu:', service);
    
    const editedServiceData = {
      ...service,
      id: service.id,
      client_id: service.client_id,
    };
    
    console.log('Przetworzone dane serwisu:', editedServiceData);
    
    setIsEditing(true);
    setEditedService(editedServiceData);
  };

  const handleDeleteService = async (serviceId: number) => {
    if (!window.confirm('Czy na pewno chcesz usunąć ten przegląd?')) return;

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`/api/services/${serviceId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Nie udało się usunąć przeglądu');
      }

      const updatedData = await fetchClientDetails(clientDetails.id);
      if (updatedData) {
        setServiceDetails(updatedData.services || []);
      }
    } catch (error) {
      console.error('Błąd podczas usuwania przeglądu:', error);
    }
  };

  if (isEditMode) {
    return (
      <EditClientSidebar
        open={open}
        onClose={onClose}
        clientDetails={clientDetails}
        onUpdate={async () => {
          await fetchServiceDetails(clientDetails.id);
        }}
      />
    );
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDrawer-paper': { width: { xs: '100%', sm: 400 } } }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Szczegóły klienta</Typography>
          <IconButton onClick={onEditClick}>
            <EditIcon />
          </IconButton>
        </Box>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Dane klienta
              </Typography>
              <Typography variant="body1">
                {clientDetails?.name}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Tel: {[clientDetails?.phone1, clientDetails?.phone2].filter(Boolean).join(', ') || '-'}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {clientDetails?.address}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Data instalacji:</strong> {cleanupDate(clientDetails.installationDate)}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Instalator:</strong> {clientDetails.installer}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Data podpisania umowy:</strong> {cleanupDate(clientDetails.agreementSigningDate)}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Data wprowadzenia umowy:</strong> {cleanupDate(clientDetails.agreementIntroductionDate)}
              </Typography>
            </Box>

            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">
                Historia przeglądów
              </Typography>
              {!isEditing && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsEditing(true);
                    setEditedService({
                      id: 0,
                      client_id: clientDetails?.id || 0,
                      scheduledServiceDate: null,
                      scheduledAnodeDate: null,
                      scheduledGlycolDate: null,
                      scheduledBy: '',
                      scheduledAmount: '0',
                      scheduledScope: '',
                      scheduledDescription: '',
                      serviceDate: null,
                      anodeDate: null,
                      glycolDate: null,
                      servicePerson: '',
                      serviceAmount: '0',
                      serviceScope: '',
                      serviceDescription: '',
                      executionAnodeDate: null,
                      executionGlycolDate: null,
                      executionPerson: '',
                      executionAmount: '0',
                      executionScope: '',
                      executionDescription: '',
                      createdAt: new Date().toISOString(),
                      updatedAt: new Date().toISOString(),
                      serviceDone: 0
                    });
                  }}
                >
                  Dodaj przegląd
                </Button>
              )}
            </Box>

            {isEditing ? (
              renderServiceForm()
            ) : (
              // Lista przeglądów
              <List>
                {Array.isArray(serviceDetails) && serviceDetails
                  .filter(service => service !== null)
                  .map((service) => (
                    <Paper key={service.id} sx={{ mb: 2 }}>
                      <ListItemButton 
                        onClick={() => setExpandedServiceId(
                          expandedServiceId === String(service.id) ? null : String(service.id)
                        )}
                      >
                        <ListItemText
                          primary={
                            <>
                              {cleanupDate(service.scheduledServiceDate || service.serviceDate)}
                              <IconButton 
                                size="small" 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleServiceEdit(service);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </>
                          }
                          secondary={`${service.serviceScope || service.scheduledScope} ${service.servicePerson || service.scheduledBy} ${service.serviceDone ? 'Wykonany' : 'Nie wykonany'}`
                        }
                        />
                        {expandedServiceId === String(service.id) ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={expandedServiceId === String(service.id)} timeout="auto" unmountOnExit>
                        <Box sx={{ p: 2 }}>
                            <>
                              <Typography variant="body2" gutterBottom>
                                <strong>Zaplanowana kwota:</strong> {service.scheduledAmount || '0'} zł
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                <strong>Zaplanowany zakres:</strong> {service.scheduledScope || 'Brak zakresu'}
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                <strong>Opis:</strong> {service.scheduledDescription || 'Brak opisu'}
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                <strong>Kwota pobrana:</strong> {service.serviceAmount || '0'} zł
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                <strong>Wykonany zakres:</strong> {service.serviceScope || 'Brak zakresu'}
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                <strong>Opis:</strong> {service.serviceDescription || 'Brak opisu'}
                              </Typography>
                            </>
                          <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={() => {
                                handleServiceEdit(service);
                              }}
                            >
                              Edytuj
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              color="error"
                              onClick={() => handleDeleteService(service.id)}
                            >
                              Usuń
                            </Button>
                          </Box>
                        </Box>
                      </Collapse>
                    </Paper>
                  ))}
              </List>
            )}
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default RightSidebar;
