import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  CircularProgress
} from '@mui/material';
import EditClientSidebar from './EditClientSidebar';
import { ClientDetails } from '../types/interfaces';
import { mapApiClientToClientDetails } from '../utils/mappers';

const TabelaKlientow: React.FC = () => {
  const [clients, setClients] = useState<ClientDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedClient, setSelectedClient] = useState<ClientDetails | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const fetchClients = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('/api/clients?lat=0&lng=0', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setClients(Array.isArray(data) ? data : Object.values(data));
    } catch (error) {
      console.error('Błąd podczas pobierania danych:', error);
      setClients([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, [getAccessTokenSilently]);

  const handleRowClick = (client: ClientDetails) => {
    setSelectedClient(client);
    setSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
    setSelectedClient(null);
  };

  const handleUpdateClient = async () => {
    // Odśwież dane po aktualizacji
    await fetchClients();
  };

  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <CircularProgress />
    </div>
  );

  return (
    <div className="p-6 mt-5 mr-5">
      <TableContainer 
        component={Paper} 
        className="shadow-lg"
        sx={{ backgroundColor: '#2C2C2C' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell 
                sx={{
                  backgroundColor: '#1E1E1E',
                  color: '#64B5F6',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  borderBottom: '2px solid #64B5F6'
                }}
              >
                Imię i nazwisko
              </TableCell>
              <TableCell 
                sx={{
                  backgroundColor: '#1E1E1E',
                  color: '#64B5F6',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  borderBottom: '2px solid #64B5F6'
                }}
              >
                Adres
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <TableRow 
                key={client.id}
                onClick={() => handleRowClick(client)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#3C3C3C',
                  },
                  '& td': {
                    borderBottom: '1px solid #404040'
                  }
                }}
              >
                <TableCell sx={{ color: '#fff' }}>{client.name}</TableCell>
                <TableCell sx={{ color: '#fff' }}>{client.address}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedClient && (
        <EditClientSidebar
          open={sidebarOpen}
          onClose={handleCloseSidebar}
          clientDetails={selectedClient}
          onUpdate={handleUpdateClient}
        />
      )}

      {clients.length === 0 && (
        <div className="text-center py-4 text-gray-400">
          Brak klientów do wyświetlenia
        </div>
      )}
    </div>
  );
};

export default TabelaKlientow;
