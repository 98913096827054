import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#64B5F6', // Jasnoniebieski
    },
    secondary: {
      main: '#81C784', // Jasnozielony
    },
    background: {
      default: '#1E1E1E', // Ciemnoszary
      paper: '#2C2C2C', // Nieco jaśniejszy szary
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
          borderRadius: '4px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#2C2C2C', // Nieco jaśniejszy szary
        },
      },
    },
  },
});

export default theme;
