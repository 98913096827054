export const cleanupDate = (date: string | null | undefined) => {
    if (date && date !== '' && date.toString().length > 10) {
        try {
            date = new Date(date).toISOString().split('T')[0];
        } catch (error) {
            return date;
        }
        
    }
    return date;
}